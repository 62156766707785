import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import * as toast from '@/services/toast'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Users extends Vue {
  password = ''
  username = ''
  typePassword = 'password'
  iconPassword = 'eye'
  name = ''
  surname = ''
  second_surname = ''
  cedula = ''
  email = ''
  password_valided = ''
  primer_apellido = ''

  changeView(): void {
    this.typePassword = this.typePassword === 'password' ? 'text' : 'password'
    this.iconPassword = this.iconPassword === 'eye' ? 'eye-off' : 'eye'
  }

  async submit() {
    try {
      const user = {
        username: this.username,
        password: this.password,
        middle_name: this.surname,
        last_name: this.second_surname,
        email: this.email,
        identity_card: this.cedula,
        first_name: this.name,
      }
    } catch (error) {
      toast.error(this.$i18n.t('toast.login_error').toString())
      console.error(error)
    }
  }
}
